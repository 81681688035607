<template>
  <div>
    <div class="pc container">
      <div class="h5 main" style="padding:40px 0 28px 0">주문제작 신청 내역</div>

      <div class="panel-container">
        <table>
          <tr>
            <th>상태</th>
            <th>서비스 이름</th>
            <th>신청 내용</th>
            <th>신청날짜</th>
          </tr>
          <tr class="menu-item unselect" v-for="(item,idx) in list" :key="'menu-'+idx" @click="clickItem(item)">
            <td width="120" class="flex-align">
              <div :class="`position-relative status status-${item.status}`">{{ contentStatus(item) }}
                <div class="badge" :style="`right:-${item.new && item.new>9 ? 16 : 13}px`" v-if="item.new">{{ item.new }}</div>
              </div>
            </td>
            <td width="200"><div class="ellipsis-1 body4 sub" style="padding-right:20px">{{ item.service_name }}</div></td>
            <td width="610" style="padding-right: 12px">
              <div class="body2 sub ellipsis-1" style="padding-right:20px">{{ item.content }}</div>
            </td>
            <td width="140"><span class="body4 sub3">{{ datesFormat(item.created_time, 'date_3') }}</span></td>
          </tr>
        </table>
        <lp-paginate :filtered="itemFiltered"
                     @getData="getData"
                     class="padding-top-80"
                     v-show="list.length>0"></lp-paginate>

        <div v-if="list && list.length === 0" class="empty-view" style="padding-top:40px">신청내역이 없습니다</div>
      </div>
    </div>

    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">주문제작 신청 내역</div>
      <div class="panel-container" v-for="(item,idx) in list" :key="'menu-'+idx" @click="clickItem(item)">
        <div class="subtitle7 main" style="margin-bottom:8px">{{ item.service_name }}</div>
        <div class="flex-align" v-if="item.last_message!==''">
          <div class="body6 sub3 ellipsis-1" style="flex:1;height:20px;padding-right:4px">{{ item.last_message }}</div>
          <div class="badge" v-if="item.new">{{ item.new }}</div>
        </div>
        <div class="flex-align" style="margin-top:8px">
          <div :class="`status status-${item.status}`">{{ contentStatus(item) }}</div>
          <div class="body6 sub3" style="margin-left:8px">{{ datesFormat(item.created_time, 'date_3') }}</div>
        </div>
      </div>
      <lp-paginate :filtered="itemFiltered"
                   @getData="getData"
                   class="padding-top-40"
                   v-show="list.length>0"></lp-paginate>

      <div v-if="list && list.length === 0" class="empty-view">신청내역이 없습니다</div>
    </div>
  </div>

</template>

<script>
  import LpPaginate from "../component/LpPaginate";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "ServiceInquiryList",
    components: {LpPaginate},
    mixins:[
      UserAPIMixin
    ],
    data() {
      return {
        list: [],
        itemFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        }
      }
    },
    created() {
      this.loginRequired();
      this.getData();
    },

    methods:{
      getData(){
        this.request.user.get('launchpack/service/inquiry?category=0', {
          params: this.itemFiltered
        }).then(res => {
            this.list = res.data.data;
            this.itemFiltered.page_length = res.data.page_length;
            this.itemFiltered.cur_page = res.data.cur_page;
            this.itemFiltered.total_page = res.data.total_page;
            this.itemFiltered.count = res.data.count;

          });

      },
      clickItem(item) {
        this.routerPush(`/service_inquiry_detail?id=${item.id}`);
      },
      contentStatus(menu) {
        let status = {
          0: '신청 미확인',
          1: '상담중',
          2: '상담완료'
        };
        let result = status[menu.status];
        if(menu.status === 2 && menu.service_status > 0) {
          result = menu.service_status === 1 ? '서비스 미등록' : '서비스 등록';
        }
        return result;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import'~assets/css/lp_main'
  table
    width 100%
  th
    padding-bottom 20px
    text-align left
  td
    padding 20px 0
    border-bottom 1px solid $gray1
  th
    border-bottom 1px solid $sub5

  .menu-header
    padding-bottom 20px
    border 1px solid $sub5
    display flex
    justify-content space-between

  .panel-container
    margin-bottom 8px

  .badge
    background-color $error
    color white
    border-radius 10px
    font-size 12px
    font-weight 500
    text-align center
    position absolute
    top -10px
    right -16px
    min-width 19px
    height 19px
    padding 0 6px
    line-height 19px
  @media (max-width:1024px)
    .badge
      top 0
      left 0
      position static

</style>
